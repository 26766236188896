@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/functions";
@import "../../../assets/scss/fonts";

.EpisodeDetails {
  background-color: $black;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 50px 15px;

  @include breakpoint(lg) {
    min-height: 100vh;
    padding: 50px 20px;
  }

  @include breakpoint(xl) {
    padding: 50px 80px;
  }

  nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    width: 100%;

    button {
      align-items: center;
      background-color: transparent;
      border: none;
      color: $white;
      cursor: pointer;
      display: flex;
      font-family: Lekton, sans-serif;
      font-size: pxToRem(22px);
      font-weight: 600;
      justify-content: center;
      margin: 0;
      outline: transparent;
      text-decoration: underline;
    }

    a {
      text-decoration: none;

      svg {
        transform: rotate(90deg);
      }
    }

    .share-buttons {
      position: relative;

      & > button svg {
        border: 2px solid $white;
        border-radius: 50%;
        height: 25px;
        padding: 5px;
        width: 25px;
      }

      div {
        display: none;
        justify-content: space-between;
        position: absolute;
        right: 0;
        top: 50px;
        width: 180px;

        &.active {
          display: flex;
        }
      }
    }
  }

  section {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(lg) {
      flex-direction: row;
    }

    article,
    & > div {
      box-sizing: border-box;
      width: 100%;

      @include breakpoint(lg) {
        width: 50%;

        &:first-child {
          padding: 0 20px 0 0;
        }
      }

      @include breakpoint(xl) {
        padding: 0 25px;
      }
    }

    h2,
    h3,
    p,
    a {
      color: $white;
      font-family: Lekton, sans-serif;
    }

    h2,
    h3 {
      text-transform: uppercase;
    }

    &:first-of-type {
      iframe {
        height: 260px;
        width: 100%;

        @include breakpoint(md) {
          height: 420px;
        }
      }

      article {
        display: flex;
        flex-direction: column;
        height: 60%;

        p {
          font-size: pxToRem(18px);
          margin: 0;

          &:first-child {
            font-weight: 600;
            margin: 10px 0;
            text-transform: uppercase;
          }

          &:nth-child(2) {
            border: 1px solid $white;
            font-size: pxToRem(15px);
            padding: 5px;
            text-transform: lowercase;
            width: max-content;

            @include breakpoint(lg) {
              margin-top: unset;
            }
          }
        }

        h2 {
          cursor: pointer;
          margin: 30px 0;
          text-decoration: underline;
        }

        div {
          align-items: center;
          display: flex;
          flex-direction: column;
          margin: 30px 0;
          padding: unset;
          width: 100%;

          @include breakpoint(lg) {
            align-items: flex-end;
            flex-direction: row;
          }

          a {
            text-decoration: none;

            &:first-child {
              button {
                border: 2px solid $white;
                font-size: pxToRem(20px);
                margin-bottom: 30px;
                text-transform: uppercase;
                padding: 10px 30px;

                @include breakpoint(lg) {
                  margin: 0 30px 0 0;
                  font-size: pxToRem(22px);
                }
              }
            }

            &:last-child {
              button {
                align-items: center;
                display: flex;
                font-size: pxToRem(20px);

                p {
                  border-bottom: 2px solid $white;
                  margin: 0;
                  text-transform: lowercase;
                  width: max-content;
                }

                svg {
                  height: 25px;
                  margin-left: 10px;
                  width: 25px;
                }
              }
            }

            button {
              background-color: transparent;
              border: none;
              color: $white;
              cursor: pointer;
              font-family: Lekton, sans-serif;
              font-weight: 600;
              outline: transparent;
            }
          }
        }
      }
    }

    &:last-of-type {
      a {
        border-bottom: 1px solid $white;
        display: block;
        overflow-x: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      div {
        margin: 0 0 30px;

        div {
          margin: 15px 0 50px;
        }
      }

      .related-news {
        .navigate {
          margin: 0;
          overflow-x: hidden;

          button {
            background: transparent;
            border: 1px solid $gray;

            &:hover{
              border: 1px solid white;
            }
            
            svg {
              fill: white;
            }
          }
        }
        div {
          display: flex;
          gap: 15px;
          overflow-x: scroll;

          &::-webkit-scrollbar {
            display: none;
          }

          a {
            overflow-x: unset;

            &:hover{
              opacity: 0.5;
            }

            img {
              height: 170px;
              object-fit: cover;
              object-position: center -70px;
              width: 250px;

              @include breakpoint(md) {
                height: 200px;
                min-width: 370px;
                object-position: center -170px;
              }
            }
          }
        }
      }
    }
  }

  hr {
    width: 45%;
  }
}
