@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/functions";
@import "../../../assets/scss/fonts";

.Tag {
  background-color: $white;
  border: 2px solid $black;
  color: $black;
  cursor: pointer;
  font-family: Lekton, sans-serif;
  font-size: pxToRem(18px);
  margin: 0;
  outline: transparent;
  padding: 10px 30px;

  &.active, &:hover {
    filter: invert(1);
  }
}
