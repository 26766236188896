@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/functions";
@import "../../../assets/scss/fonts";

.Episode {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;

  @include breakpoint(lg) {
    overflow: hidden;
    position: relative;

    &:hover .overlay {
      opacity: 1;

      p {
        opacity: 1;
        transform: scale(1);
      }
    }

    &:hover .text {
      opacity: 1;
    }

    &.up {
      @include breakpoint(lg) {
        position: relative;
        top: -80px;
      }
    }
  }

  img {
    width: 100%;

    @include breakpoint(md) {
      width: 300px;
    }

    @include breakpoint(lg) {
      padding: 0;
      width: 300px;
    }

    @include breakpoint(xl) {
      width: 350px;
    }

    @include breakpoint(xxl) {
      width: 400px;
    }
  }

  .overlay {
    display: none;

    @include breakpoint(lg) {
      align-items: center;
      background: rgba(117, 117, 117, 0.7);
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }

    a {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-decoration: none;

      p {
        color: white;
        font-family: Lekton;
        font-size: pxToRem(24px);
        font-weight: 400;
        opacity: 0;
        text-align: center;
        transform: scale(1.2);
        transition: all 0.2s ease-in-out 0s;
        width: 85%;

        @include breakpoint(lg) {
          font-weight: 600;
          letter-spacing: 1.48px;
          margin: 0;
          padding: 0;
        }

        @include breakpoint(xl) {
          padding: 0 25px;
        }
      }

      button {
        display: none;

        @include breakpoint(lg) {
          background: transparent;
          border: 2px solid $white;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          margin: 15px;
          outline: transparent;
          padding: 5px;
          width: max-content;
        }

        svg {
          height: 25px;
          width: 25px;
        }
      }
    }
  }
}

.inactive {
  opacity: 0.2;
}
