@import "../../../assets/scss/functions";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.Home {
  align-items: center;
  background-color: $black;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;

  @include breakpoint(lg) {
    min-height: 100vh;
  }
}
