$black: #000;
$white: #fff;
$gray: #757575;

$breakpoints: (
  "xs": 400px,
  "sm": 576px,
  "md":768px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1400px,
  "xxxl": 1600px
);
