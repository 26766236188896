@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/functions";
@import "../../../assets/scss/fonts";

.Nav {
  align-items: center;
  box-sizing: border-box;
  color: $white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
  width: 100%;

  @include breakpoint(lg) {
    justify-content: space-between;
    padding-top: 42px;
  }

  @include breakpoint(xl) {
    padding: 42px 20px;
  }

  @include breakpoint(xxl) {
    padding: 42px 80px;
  }

  svg {
    cursor: pointer;
    filter: invert(1);

    @include breakpoint(lg) {
      display: none;
    }
  }

  h2 {
    font-family: Lekton, sans-serif;
    font-size: pxToRem(22px);
    font-weight: 600;
    margin: 41px 0;
    text-transform: uppercase;
    white-space: nowrap;

    @include breakpoint(lg) {
      font-size: pxToRem(40px);
    }
  }

  div {
    display: flex;

    &:first-child {
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @include breakpoint(lg) {
        width: max-content;
      }

      .logo {
        flex-direction: column;
        width: max-content;

        @include breakpoint(lg) {
          width: 297px;
        }

        img {
          width: 81px;

          @include breakpoint(lg) {
            width: 178px;
          }
        }

        p {
          display: none;
          font-family: Lekton;
          font-size: pxToRem(15px);

          @include breakpoint(lg) {
            display: block;
          }
        }
      }

    }

    &:last-child {
      display: none;

      @include breakpoint(lg) {
        display: block;

        p {
          font-family: Lekton;
          font-size: pxToRem(22px);
          font-weight: 600;
          text-transform: uppercase;
        }

        div {
          @include breakpoint(lg) {
            align-items: flex-end;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            a {
              height: max-content;
              text-decoration: none;

              &:first-child {
                img {
                  border-bottom: 2px solid #FF2600;
                  width: 115px;
                }
              }

              &:last-child {
                img {
                  border-bottom: 2px solid #08DA73;
                  width: 100px;
                }
              }

              img {
                cursor: pointer;
                padding: 10px 5px;
              }
            }
          }
        }
      }
    }
  }
}
