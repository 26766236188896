@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/functions";
@import "../../../assets/scss/fonts";

.SearchBar {
  align-items: center;
  display: flex;
  flex-direction: column;

  input {
    background-color: $white;
    background-image: url("../../../assets/images/search.svg");
    background-position: 415px center;
    background-repeat: no-repeat;
    border: none;
    box-sizing: border-box;
    color: $black;
    display: none;
    font-family: Lekton, sans-serif;
    font-size: pxToRem(22px);
    height: 45px;
    outline: transparent;
    padding: 0 40px 0 10px;
    width: 90%;

    &::placeholder {
      color: $gray;
      font-family: Lekton, sans-serif;
      font-size: pxToRem(14px);

      @include breakpoint(lg) {
        font-size: pxToRem(20px);
      }
    }

    @include breakpoint(lg) {
      display: block;
      width: 450px;
    }

    &.active{
      display: block;
    }
  }

  & > div {
    align-items: center;
    display: flex;
    flex-direction: column;

    & > p {
      border-bottom: 1px solid $white;
      color: $white;
      cursor: pointer;
      font-family: Lekton;
      font-size: pxToRem(22px);
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    svg {
      cursor: pointer;
      transition: 0.8s ease-out;

      &.active {
        transform: rotate(180deg);
      }
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-top: 15px;
      max-height: 0;
      overflow: hidden;
      transition: 0.7s ease-in-out;

      @include breakpoint(lg) {
        gap: 15px;
      }

      &.active {
        max-height: 900px;
      }
    }
  }
}
