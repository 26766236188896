@mixin font-face($font-family, $font-suffix, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url($font-suffix) format("truetype"),
    url($font-suffix) format("opentype");
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@include font-face(Lekton, "../fonts/Lekton-Bold.ttf", 600, bold);
@include font-face(Lekton, "../fonts/Lekton-Regular.ttf", 400, regular);
