@import "../../../assets/scss/variables";
@import "../../../assets/scss/functions";
@import "../../../assets/scss/mixins";

footer {
  align-items: center;
  background-color: $black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 70px;
  width: 100%;

  @include breakpoint(lg) {
    padding-bottom: 50px;
  }

  section {
    width: 90%;

    &:nth-child(1) {
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.43);
      display: flex;
      flex-direction: column;
      height: fit-content;
      justify-content: space-around;

      @include breakpoint(md) {
        flex-direction: row;
        height: 400px;
        justify-content: space-between;
      }

      .mt-video{
        display: none;

        @include breakpoint(lg) {
          display: flex;
        }
      }

      div {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: fit-content;
        justify-content: flex-start;
        width: 100%;

        @include breakpoint(md) {
          align-items: flex-start;
          height: 200px;
          width: 30%;
        }

        .mt-links {
          @include breakpoint(lg) {
            padding: 0 30px;
            width: fit-content;
          }
        }

        h3 {
          color: white;
          font-size: 1.12rem;
          font-weight: bold;
          margin-bottom: 15px;
        }

        p,
        a {
          color: $white;
          font-size: 1rem;
          margin: 0 10px 10px 0;
          text-decoration: none;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }

        .products {
          border: none;
          display: flex;
          padding: 0;
          width: 100%;

          @include breakpoint(md) {
            flex-direction: row;
          }
        }

        .contact {
          p {
            cursor: initial;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }

    &:nth-child(2) {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 200px;
      justify-content: space-around;

      @include breakpoint(md) {
        flex-direction: row;
        height: 100px;
        justify-content: space-between;
      }

      p {
        color: $white;
      }

      div {
        display: flex;

        @include breakpoint(lg) {
          width: 33.3%;
        }

        &:nth-child(2) {
          justify-content: center;
        }

        &:last-child {
          justify-content: flex-end;

          svg {
            margin-left: 10px;
            width: 35px;
          }
        }
      }
    }
  }
}
