@import "../../../assets/scss/functions";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.EpisodeList {
  background-color: $black;
  display: flex;
  flex-flow: row wrap;
  gap: 15px;
  margin-top: 160px;
  width: 100%;

  @include breakpoint(md) {
    padding-left: 0;
    max-width: 630px;
  }

  @include breakpoint(lg) {
    max-width: 945px;
  }

  @include breakpoint(xl) {
    max-width: 1095px;
  }

  @include breakpoint(xxl) {
    max-width: 1245px;
  }

  .load-more {
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.568);
    color: $white;
    cursor: pointer;
    font-family: Lekton;
    font-size: pxToRem(22px);
    margin: 15px auto 30px;
    padding: 5px;

    @include breakpoint(lg) {
      margin: auto;
    }
  }
}
